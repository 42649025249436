import { Loader } from 'components'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { OfficeHoursForm } from 'domains/customer/screens/OfficeHour/components'

import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { buildPath, paths } from 'routes'
import { OfficeHoursPayload } from 'services/officeHours/types'
import { useToast } from 'shared/hooks'
import { usePostOfficeHours } from 'shared/hooks/services/officeHours/usePostOfficeHours'

const Create = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()
  const { mutate, status } = usePostOfficeHours()
  const { account } = useCustomerContext()

  const onSubmit = useCallback(
    (formData: OfficeHoursPayload) => {
      mutate(
        { payload: formData },
        {
          onSuccess: () => {
            addToast({ message: 'Horário cadastrado com sucesso.' })
            navigate(
              buildPath({
                path: paths.account.details + '?tab=officeHour&page=1',
                params: { accountId: account?.id },
              }),
            )
          },
          onError: () => {
            addToast({
              message: 'Não foi possível cadastrar horário. Tente novamente.',
              type: 'alert',
            })
          },
        },
      )
    },
    [mutate, addToast, navigate],
  )

  return (
    <>
      <Loader isVisible={status === 'pending'} />
      <OfficeHoursForm onSubmit={(formData) => onSubmit(formData)} />
    </>
  )
}

export default Create
